import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

interface SeoProps {
  title?: string;
  image?: string;
  description?: string;
  descriptionFr: string;
  descriptionEn: string;
  children?: React.ReactNode;
  location?: string;
}

const Seo = ({ title, image, location,descriptionEn,descriptionFr }: SeoProps) => {
  let str = ''
  let Location = ''
  let withoutLastSegement = ''
  if(typeof window !== 'undefined') {
      Location = window.location.pathname
      str = Location.replace(/^\/([^\/]*).*$/, '$1')
      withoutLastSegement = Location.substring(0,Location.lastIndexOf("/"));
  }

  const seo = {
    title: title ?? "Shukran",
    description: str == 'en-us' ? descriptionEn :descriptionFr,
    image: image ?? "https://images.prismic.io/shukran/51700c4f-b51e-468f-83e4-c751e6fee2d5_Social-Sharing-image-preview.jpg",
    siteUrl: "https://getshukran.com"
  };
  return (
    
    <Helmet>
      <html lang="en" dir="ltr" />
      <meta charSet="utf-8" key="charset"/>
      <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'/>
      <title>{title?title:seo.title}</title>
      <meta name="twitter:title" content={title?title:seo.title}/>
      <meta property="og:title" content={title?title:seo.title}/>
      <meta name="description" content={seo.description? seo.description : "no description"}/>
      <meta name="twitter:description" content={seo.description? seo.description : "no description"}/>
      <meta property="og:description" content={seo.description? seo.description : "no description"}/>
      <meta property="og:type" content="website"/>
      <meta name="twitter:site" content="@shukran"/>
      <meta name="twitter:creator" content="@BCL"/>
      <meta property="og:site_name" content="Shukran"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:image" content={seo.image}/>
      <meta property="og:image" content={seo.image}/>
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.string,
  location: PropTypes.string,
};

export default Seo;