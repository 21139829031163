import React from 'react'
import styled from 'styled-components'
import Donation from '../components/Donation'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

function donation() {
  return (
    <Layout border='2px solid #424242' lang='fr-fr'>
        <Seo title='Donation | Shukran' descriptionEn='Donate to Shukran'  descriptionFr='Faîtes un don à Shukran'/>
        <Donation fr={true}/>
    </Layout>
  )
}

export default donation