import { Slider } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { loadStripe } from '@stripe/stripe-js';
import { Link } from 'gatsby';

const Wrap = styled.div`
    margin: 112px 346px;
    @media screen and (max-width:1250px) {
        margin: 112px 300px;
    }
    @media screen and (max-width:1150px) {
        margin: 112px 250px;
    }
    @media screen and (max-width:1000px) {
        margin: 112px 200px;
    }
    @media screen and (max-width:850px) {
        margin: 112px 150px;
    }
    @media screen and (max-width:800px) {
        margin: 112px 32px;
    }
`
const SmallTitle = styled.h4`
    font-family: 'SF Pro Rounded Bold';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
/* identical to box height */


/* Shukran Rechta DS/Links/Color 2 */

color: #4CD964;
`
const BigTitle = styled.h1`
    font-family: 'SF Pro Rounded Bold';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 57px;
margin-top:-1rem;
margin-bottom:3rem;
/* Shukran Rechta DS/Text/Color 2 */

color: #000000;

`
const SmallP = styled.p`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;

/* Shukran Rechta DS/Text/Color 3 */

color: #424242;
`
const BigP = styled.p`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 33px;

/* Shukran Rechta DS/Text/Color 2 */

color: #000000;

`
const OrdinaryP = styled.p`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;

/* Shukran Rechta DS/Text/Color 2 */

color: #000000;

`
const SlideWrap = styled.div`
    margin: 3rem 0;
`
const SlideInterval = styled.div`
display: flex;

`
const Interval = styled.span`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;

/* Shukran Rechta DS/Text/Color 3 */

color: #424242;
`
const Donate = styled.a`
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 32px;
background: #FCFCFC;
border: 1px solid #E0E0E0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 8px;
flex: none;
order: 5;
align-self: stretch;
flex-grow: 0;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
margin-top: -2rem;
color: #4F4F4F;
transition: .5s all ease;
&:hover {
    border: 1px solid #4F4F4F;
    transition: .5s all ease;
}
`
const Btn = styled(Link)`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 14px 24px;

width: 79.43px;
height: 48px;

background: #FCFCFC;
/* Gray 5 */
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

color: #4F4F4F;
border: 1px solid #E0E0E0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 8px;
`
const BtnWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    @media screen and (max-width: 1500px) {
        flex-wrap: wrap;
    }
`
interface DonationProps {
    fr: boolean
}

const Donation: React.FC<DonationProps> = ({fr}) => {
    
    const [value , setValue] = React.useState(125)
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue);
      };
      function valuetext(value: number) {
        return `${value}€`;
      }
    
        return (
            <Wrap>
                <SmallTitle>
                Donation
                </SmallTitle>
                <BigTitle>
              {fr ? 'Faîtes un don à Shukran' : 'Donate to Shukran'}
                </BigTitle>
                <SmallP>
                {fr ? 'Selon Abou Hourayra (radhia Allâhou anhou), le Prophète (paix et bénédiction de dieu soit sur lui) a dit : “Celui qui appelle au bien aura la récompense de celui qui le suivra, sans que leurs récompenses (respectives) ne soient pour autant diminuées.”' : 'According to Abu Hourayra (radhia Allâhou anhou), the Prophet (peace and blessings of God be upon him) said: “He who calls for good will have the reward of whoever follows him, without their (respective) rewards being diminished.”'} 
                </SmallP>
                <BigP>
                {fr ? 'Svp donnez seulement ce que vous pouvez vous permettre de donner.' : 'Please only give what you can afford to give.'}
                </BigP>
                <BigP>
                {fr ? 'SVP aussi, prenez note que nous ne sommes pas habilités à recevoir votre zakat, en effet la zakat doit revenir aux plus nécessiteux.' : 'Please also note that we are not entitled to receive your zakat, indeed the zakat must go to the most needy.'}
                </BigP>
                <OrdinaryP>
                {fr ? 'Votre don servira à : rémunérer l’équipe fondatrice, à embaucher plus de prestataires pour ajouter de nouveaux produits, vérifier l’exactitude des ingrédients, contacter les marques pour demander des précisions, continuer les recherches afin de rendre l’application la plus fiable possible, payer les développeurs de l’application afin d’ajouter de nouvelles fonctionnalités, ainsi qu’à investir plus dans le marketing afin de mieux faire connaître l’application et aider un maximum de musulmans.' : 'Your donation will be used to: remunerate the founding team, hire more service providers to add new products, verify the accuracy of ingredients, contact brands to ask for clarification, continue research to make the application as reliable as possible. , pay app developers to add new features, and invest more in marketing to raise awareness of the app and help as many Muslims as possible.'}
                </OrdinaryP>
            {/* <SlideWrap>
            <SlideInterval>
                <Interval style={{ flex: '1'}}>
                10€
                </Interval>
                <Interval>
                250€
                </Interval>
            </SlideInterval>
            <Slider  
            value={typeof value === 'number' ? value : 0} 
            min={10} onChange={handleSliderChange}
            max={250} 
            aria-labelledby="input-slider"
            getAriaValueText={valuetext} 
            valueLabelFormat={valuetext} 
            valueLabelDisplay="auto" />
           
            </SlideWrap> */}
                <BtnWrap>
                    <Btn to='https://donate.stripe.com/fZe4gTeqBeSZbD29AC'>10€</Btn>
                    <Btn to='https://donate.stripe.com/fZe5kX1DP6mt9uU003'>25€</Btn>
                    <Btn to='https://donate.stripe.com/8wMdRtgyJeSZcH628c'>50€</Btn>
                    <Btn to='https://donate.stripe.com/6oE7t56Y96mtfTieUZ'>100€</Btn>
                    <Btn to='https://donate.stripe.com/bIY14H1DPeSZ4aA9AG'>250€</Btn>
                    <Btn to='https://donate.stripe.com/4gw9BdfuF8uBbD228f'>500€</Btn>
                    <Btn to='https://donate.stripe.com/cN23cP1DP6mt0YocMU'>1000€</Btn>
                </BtnWrap>
          
            </Wrap>
        );
}
export default Donation